import { useEffect, useMemo, useState } from 'react';
import IconBadge from '../../shared/icon/IconBadge';
import CommentIcon from '../../shared/icon/CommentIcon';
import { EventSystem } from '../../../events/EventSystem';
import { Risk, RiskType } from '../../../models/Risk';
import { groupBy } from '../../../utils/ListUtils';
import RiskActionIndicator from '../../risk/RiskActionIndicator';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { useFormAction } from '../../../contexts/FormActionContext';
import Tooltip from '../../shared/Tooltip';
import { useTranslation } from 'react-i18next';
import { useCurrentActivityCommentsStats } from '../../../global-state/Forms';

const ActionIndicators = () => {
  const { rootContext } = useFormRendererInfo();
  const { currentAction, currentSection } = useFormAction();
  const [flaggedRisks, setFlaggedRisks] = useState<Risk[]>([]);
  const { t } = useTranslation(['comments']);

  const commentStats = useCurrentActivityCommentsStats((x) => x.value);

  const sourceId = useMemo(() => (currentAction.fieldId ? currentAction.fieldId : currentAction.id), [currentAction.fieldId, currentAction.id]);

  const commentCount = useMemo(() => {
    return commentStats.actionCommentsCounts[sourceId];
  }, [commentStats.actionCommentsCounts, sourceId]);

  useEffect(() => {
    setFlaggedRisks(rootContext?.allFlaggedRisks?.filter((x) => x.actionId === currentAction.id && x.type !== RiskType.calculated) || []);
  }, [currentAction.id, rootContext?.allFlaggedRisks]);

  const groupedFlaggedRisks = useMemo(() => {
    return groupBy(flaggedRisks, 'riskRating');
  }, [flaggedRisks]);

  return (
    <>
      {[...groupedFlaggedRisks.keys()].map((key) => (
        <div
          key={key}
          onClick={() => {
            EventSystem.fireEvent('highlight-risk', { riskIds: groupedFlaggedRisks.get(key)!.map((x) => x.id) });
            if (!currentAction || !currentSection || !rootContext?.clientForm) return;
            EventSystem.fireEvent('highlight-question', {
              sourceId: currentAction.id,
              sectionId: currentSection.id,
              formId: rootContext?.clientForm?.id,
              isRisk: true,
            });
          }}
        >
          <RiskActionIndicator riskRating={key} text={groupedFlaggedRisks.get(key)!.length} />
        </div>
      ))}
      {commentCount > 0 && (
        /* Open the thread for the Question */
        <Tooltip text={t('comment-count', { count: commentCount })}>
          {(tooltip) => (
            <div {...tooltip}>
              <IconBadge
                icon={<CommentIcon className="h-4 w-4 flex-shrink-0" />}
                text={commentCount}
                onClick={() => EventSystem.fireEvent('question-comment-open', { sourceId: sourceId, sectionId: currentSection.id })}
              />
            </div>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default ActionIndicators;
